import React from 'react';
import Header from "./header.jsx";
import Carousel from "./carousel.jsx";
import Footer from "./footer.jsx";
import Message from './message.jsx';
import "./styles/styles.css";
import "./styles/overrides.css";
import { GraphQLClient } from "graphql-request";
import ReactDOM from 'react-dom';

/**
 * @return {int|null}
 */
export const getImprintid = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let imprintid = urlParams.get("imprintid");

    const rewrites = [
        { path: /^\/dannyjones\/1(\/|\?|$)/i, id: 4529 },
        { path: /^\/dannyjones\/2(\/|\?|$)/i, id: 4530 },
        { path: /^\/dannyjones\/3(\/|\?|$)/i, id: 4533 },
        { path: /^\/BiancaDelRio\/1(\/|\?|$)/i, id: 4584 },
        { path: /^\/FemiKuti\/1(\/|\?|$)/i, id: 4583 },
        { path: /^\/JuliaMichaels\/1(\/|\?|$)/i, id: 4582 },
        { path: /^\/Khalid\/1(\/|\?|$)/i, id: 4562 },
        { path: /^\/JuliaMichaels\/2(\/|\?|$)/i, id: 4581 },
        { path: /^\/Khalid\/2(\/|\?|$)/i, id: 4563 },
        { path: /^\/BigZuu\/1(\/|\?|$)/i, id: 4580 },
        { path: /^\/PaleWaves\/1(\/|\?|$)/i, id: 4585 },
        { path: /^\/NewHopeClub\/1(\/|\?|$)/i, id: 4564 },
        { path: /^\/FettyWap\/1(\/|\?|$)/i, id: 4579 },
        { path: /^\/PaleWaves\/2(\/|\?|$)/i, id: 4578 },
        { path: /^\/Hozier\/1(\/|\?|$)/i, id: 4577 },
        { path: /^\/NewHopeClub\/2(\/|\?|$)/i, id: 4565 },
        { path: /^\/BigZuu\/0(\/|\?|$)/i, id: 4590 },
        { path: /^\/NewHopeClub\/0(\/|\?|$)/i, id: 4569 },
        { path: /^\/BiancaDelRio\/0(\/|\?|$)/i, id: 4586 },
        { path: /^\/FemiKuti\/0(\/|\?|$)/i, id: 4587 },
        { path: /^\/JuliaMichaels\/0(\/|\?|$)/i, id: 4588 },
        { path: /^\/Khalid\/0(\/|\?|$)/i, id: 4568 },
        { path: /^\/PaleWaves\/0(\/|\?|$)/i, id: 4591 },
        { path: /^\/FettyWap\/0(\/|\?|$)/i, id: 4592 },
        { path: /^\/Hozier\/0(\/|\?|$)/i, id: 4593 },
        { path: /^\/NewHopeClub\/3(\/|\?|$)/i, id: 4624 },
        { path: /^\/NewHopeClub\/4(\/|\?|$)/i, id: 4625 },
        { path: /^\/NewHopeClub\/5(\/|\?|$)/i, id: 4626 },
        { path: /^\/TwoDoorCinemaClub\/0(\/|\?|$)/i, id: 4635 },
        { path: /^\/TwoDoorCinemaClub\/1(\/|\?|$)/i, id: 4627 },
        { path: /^\/TwoDoorCinemaClub\/2(\/|\?|$)/i, id: 4628 },
        { path: /^\/TwoDoorCinemaClub\/3(\/|\?|$)/i, id: 4629 },
        { path: /^\/Cher\/0(\/|\?|$)/i, id: 4636 },
        { path: /^\/Cher\/1(\/|\?|$)/i, id: 4630 },
        { path: /^\/TomWalker\/0(\/|\?|$)/i, id: 4637 },
        { path: /^\/TomWalker\/1(\/|\?|$)/i, id: 4631 },
        { path: /^\/TomWalker\/2(\/|\?|$)/i, id: 4703 },
        { path: /^\/TomWalker\/3(\/|\?|$)/i, id: 4705 },
        { path: /^\/TomWalker\/4(\/|\?|$)/i, id: 4707 },
        { path: /^\/TomWalker\/5(\/|\?|$)/i, id: 4708 },
        { path: /^\/Blackbear\/0(\/|\?|$)/i, id: 4638 },
        { path: /^\/Blackbear\/1(\/|\?|$)/i, id: 4632 },
        { path: /^\/Blackbear\/2(\/|\?|$)/i, id: 4633 },
        { path: /^\/Blackbear\/3(\/|\?|$)/i, id: 4634 },
        { path: /^\/Lizzo\/0(\/|\?|$)/i, id: 4723 },
        { path: /^\/Lizzo\/1(\/|\?|$)/i, id: 4700 },
        { path: /^\/Lizzo\/2(\/|\?|$)/i, id: 4701 },
        { path: /^\/Lizzo\/3(\/|\?|$)/i, id: 4702 },
        { path: /^\/Lizzo\/4(\/|\?|$)/i, id: 4704 },
        { path: /^\/GerryCinnamon\/0(\/|\?|$)/i, id: 4724 },
        { path: /^\/GerryCinnamon\/1(\/|\?|$)/i, id: 4706 },
        { path: /^\/GerryCinnamon\/2(\/|\?|$)/i, id: 4709 },
        { path: /^\/GerryCinnamon\/3(\/|\?|$)/i, id: 4710 },
        { path: /^\/ScoutingForGirls\/0(\/|\?|$)/i, id: 4728 },
        { path: /^\/ScoutingForGirls\/1(\/|\?|$)/i, id: 4711 },
        { path: /^\/ScoutingForGirls\/2(\/|\?|$)/i, id: 4716 },
        { path: /^\/ScoutingForGirls\/3(\/|\?|$)/i, id: 4721 },
        { path: /^\/ScoutingForGirls\/4(\/|\?|$)/i, id: 4722 },
        { path: /^\/Yungblud\/0(\/|\?|$)/i, id: 4726 },
        { path: /^\/Yungblud\/1(\/|\?|$)/i, id: 4712 },
        { path: /^\/Yungblud\/2(\/|\?|$)/i, id: 4713 },
        { path: /^\/Yungblud\/3(\/|\?|$)/i, id: 4714 },
        { path: /^\/Yungblud\/4(\/|\?|$)/i, id: 4715 },
        { path: /^\/Yungblud\/5(\/|\?|$)/i, id: 4720 },
        { path: /^\/Sigrid\/1(\/|\?|$)/i, id: 4717 },
        { path: /^\/Sigrid\/2(\/|\?|$)/i, id: 4718 },
        { path: /^\/TheLibertines\/0(\/|\?|$)/i, id: 4801 },
        { path: /^\/TheLibertines\/1(\/|\?|$)/i, id: 4771 },
        { path: /^\/TheLibertines\/2(\/|\?|$)/i, id: 4776 },
        { path: /^\/TheLibertines\/3(\/|\?|$)/i, id: 4789 },
        { path: /^\/TheLibertines\/4(\/|\?|$)/i, id: 4791 },
        { path: /^\/TheLibertines\/5(\/|\?|$)/i, id: 4792 },
        { path: /^\/TheLibertines\/6(\/|\?|$)/i, id: 4793 },
        { path: /^\/TheLibertines\/7(\/|\?|$)/i, id: 4794 },
        { path: /^\/TheLibertines\/8(\/|\?|$)/i, id: 4795 },
        { path: /^\/LewisCapaldi\/0(\/|\?|$)/i, id: 4802 },
        { path: /^\/LewisCapaldi\/1(\/|\?|$)/i, id: 4772 },
        { path: /^\/LewisCapaldi\/2(\/|\?|$)/i, id: 4781 },
        { path: /^\/LewisCapaldi\/3(\/|\?|$)/i, id: 4784 },
        { path: /^\/SamFender\/0(\/|\?|$)/i, id: 4804 },
        { path: /^\/SamFender\/1(\/|\?|$)/i, id: 4774 },
        { path: /^\/SamFender\/2(\/|\?|$)/i, id: 4777 },
        { path: /^\/SamFender\/3(\/|\?|$)/i, id: 4782 },
        { path: /^\/SamFender\/4(\/|\?|$)/i, id: 4785 },
        { path: /^\/SamFender\/5(\/|\?|$)/i, id: 4787 },
        { path: /^\/SamFender\/6(\/|\?|$)/i, id: 4788 },
        { path: /^\/SamFender\/7(\/|\?|$)/i, id: 4797 },
        { path: /^\/SamFender\/8(\/|\?|$)/i, id: 4799 },
        { path: /^\/SamFender\/9(\/|\?|$)/i, id: 4800 },
        { path: /^\/idles\/0(\/|\?|$)/i, id: 4806 },
        { path: /^\/idles\/1(\/|\?|$)/i, id: 4780 },
        { path: /^\/MelanieMartinez\/0(\/|\?|$)/i, id: 4807 },
        { path: /^\/MelanieMartinez\/1(\/|\?|$)/i, id: 4779 },
        { path: /^\/MelanieMartinez\/2(\/|\?|$)/i, id: 4783 },
        { path: /^\/MelanieMartinez\/3(\/|\?|$)/i, id: 4786 },
        { path: /^\/MelanieMartinez\/4(\/|\?|$)/i, id: 4790 },
        { path: /^\/Rosalia\/1(\/|\?|$)/i, id: 4778 },
        { path: /^\/BombayBicycleClub\/0(\/|\?|$)/i, id: 4877 },
        { path: /^\/BombayBicycleClub\/1(\/|\?|$)/i, id: 4879 },
        { path: /^\/BombayBicycleClub\/2(\/|\?|$)/i, id: 4880 },
        { path: /^\/BombayBicycleClub\/3(\/|\?|$)/i, id: 4649 },
        { path: /^\/BombayBicycleClub\/4(\/|\?|$)/i, id: 4884 },
        { path: /^\/BombayBicycleClub\/5(\/|\?|$)/i, id: 4885 },
        { path: /^\/KreptAndKonan\/0(\/|\?|$)/i, id: 4878 },
        { path: /^\/KreptAndKonan\/1(\/|\?|$)/i, id: 4881 },
        { path: /^\/KreptAndKonan\/2(\/|\?|$)/i, id: 4883 },
        { path: /^\/Mabel\/0(\/|\?|$)/i, id: 4940 },
        { path: /^\/Foals\/0(\/|\?|$)/i, id: 4941 },
        { path: /^\/Foals\/1(\/|\?|$)/i, id: 4939 },
        { path: /^\/SteelPanther\/0(\/|\?|$)/i, id: 4942 },
        { path: /^\/SteelPanther\/1(\/|\?|$)/i, id: 4945 },
        { path: /^\/SteelPanther\/2(\/|\?|$)/i, id: 4928 },
        { path: /^\/SteelPanther\/3(\/|\?|$)/i, id: 4929 },
        { path: /^\/SteelPanther\/4(\/|\?|$)/i, id: 4930 },
        { path: /^\/SteelPanther\/5(\/|\?|$)/i, id: 4932 },
        { path: /^\/SteelPanther\/6(\/|\?|$)/i, id: 4935 },
        { path: /^\/BowlingForSoup\/0(\/|\?|$)/i, id: 4943 },
        { path: /^\/BowlingForSoup\/1(\/|\?|$)/i, id: 4931 },
        { path: /^\/BowlingForSoup\/2(\/|\?|$)/i, id: 4933 },
        { path: /^\/BowlingForSoup\/3(\/|\?|$)/i, id: 4934 },
        { path: /^\/BowlingForSoup\/4(\/|\?|$)/i, id: 4936 },
        { path: /^\/Example\/1(\/|\?|$)/i, id: 4937 },
        { path: /^\/Example\/2(\/|\?|$)/i, id: 4938 },
    ];

    if (!imprintid) {
        for (let rewrite of rewrites) {
            if (rewrite.path.test(window.location.pathname)) {
                imprintid = rewrite.id;
                break;
            }
        }
    }

    return imprintid;
};

export class CarouselComponent extends React.Component {
    state = {
        collectionId: 0
    };

    constructor(props) {
        super(props);
        this.state = {
            collectionId: props.collectionId
        };
    }

    render() {
        return (
            <div>
                <h2 className="highlightedList__heading">More gigs</h2>
                <Carousel collectionId={this.props.collectionId} />
            </div>
        );
    }
}

class CarouselContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            title: "",
            collectionId: 0,
            location: ""
        };

        this.main().then(result => {
            this.setState({
                title: result.artist,
                collectionId: result.collectionId,
                location: result.location
            });

            if (this.state.title != "")
                document.title = this.state.title + " at " + this.state.location;
        });
    }

    componentDidMount() {
        const imprintid = getImprintid();

        var container = document.querySelector("#amondo-example");

        if (amondo !== undefined) {
            amondo.imprint.create(container, {
                id: imprintid,
                layout: {
                    logo: false, // default true
                    cover: false, // default true
                    title: false, // default true
                    date: true, // default true
                    location: true, // default true
                    footerText: false, // default true
                    footerSocial: false, // default true
                    poweredBy: false
                },
                theme: {
                    ui: "dark", // default 'light'
                    accentColor: "#131443", // default '#000000'
                    foreground: "#0090d0", // default '#ffffff'
                    background: "#FFFFFF" // default '#FFEA27'
                }
            });
        }

        const isPreTourUrl = window.location.href.endsWith('0');

        if(isPreTourUrl) {
            document.body.classList.add("pre-tour");
        }
    }

    async main() {
        const imprintid = getImprintid();

        if(!imprintid) {
            return {
                artist: "",
                collectionId: null,
                location: ""
            };
        }

        const urlParams = new URLSearchParams(window.location.search);
        const isgig = urlParams.get("isgig");
        const endpoint = "https://graphql.amondo.com/";

        // sets without flag
        if (isgig !== null) {
            document.body.classList.add("without");
        }


        const graphQLClient = new GraphQLClient(endpoint, {
            headers: {
                authorization:
                    "Bearer BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTE3OSwicm9sZSI6InBhcnRuZXIiLCJlbWFpbCI6ImV0QGFtb25kby5jb20iLCJpYXQiOjE1NjQxNTI2MTAsImV4cCI6MTU2NjU3MTgxMH0.qGpEz41cWFgnxFRR-17ZFbzdkWZCmFB1gdsSPT_qEn8"
            }
        });

        const query =
            /* GraphQL */ `
            query imprint {
                imprint(id: ${imprintid}) {
                  artist
                  locationSearch
                  location
                  startDate
                  collections {
                    name,
                    id,
                    imprints {
                    id,
                        artist,
                        location,
                        startDate,
                        imageUrl
                    }
                  }
                  tiles {
                    id
                    username
                    fileUrl
                  }
                  tilesOrder
                }
            }
        `;

        const dataReturned = await graphQLClient.request(query);
        let associatedCollectionId = 0;

        if (dataReturned.imprint.collections.length === 0) {
            console.info(
                "No collections are associated to this artist, please contact Amondo"
            );
        } else {
            associatedCollectionId = dataReturned.imprint.collections[0].id;
        }

        return {
            artist: dataReturned.imprint.artist,
            collectionId: associatedCollectionId,
            location: dataReturned.imprint.location
        };
    }

    render() {

        if(this.state.collectionId === null) {
            return (
                <div className="body-wrapper">
                    <Header artistTitle="THE STAGE IS EMPTY"
                            location="" error/>

                    <Message heading={["We have lots of great stuff related to the artists playing at O2 venues.", <br/>, "Just not on this page."]} message="If you’re going or went to a gig, look forward to getting a message taking you to the artist page."/>

                    <Footer />
                </div>
            );

        }

        return (
            <div className="body-wrapper">
                <Header artistTitle={this.state.title}
                        location={this.state.location} />

                <div id="amondo-example" />

                <div id="carousel">
                    <CarouselComponent collectionId={this.state.collectionId} />
                </div>

                <Footer />
            </div>
        );
    }
}

export default CarouselContainer;

const wrapper = document.getElementById("root");
wrapper ? ReactDOM.render(<CarouselContainer />, wrapper) : false;
