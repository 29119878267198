"use strict";

import React from "react";
import classnames from "classnames";
import { canGoNext } from "./utils/innerSliderUtils.jsx";

export class PrevArrow extends React.PureComponent {
  clickHandler(options, e) {
    if (e) {
      e.preventDefault();
    }
    this.props.clickHandler(options, e);
  }
  render() {
    let prevClasses = {
      "slick-arrow horizScroll__control horizScroll__control--left": true,
      "slick-prev horizScroll__control horizScroll__control--left": true
    };
    let prevHandler = this.clickHandler.bind(this, { message: "previous" });

    if (
      !this.props.infinite &&
      (this.props.currentSlide === 0 ||
        this.props.slideCount <= this.props.slidesToShow)
    ) {
      prevClasses["slick-disabled"] = true;
      prevHandler = null;
    }

    let prevArrowProps = {
      key: "0",
      "data-role": "none",
      className: classnames(prevClasses),
      style: { display: "block" },
      onClick: prevHandler
    };
    let customProps = {
      currentSlide: this.props.currentSlide,
      slideCount: this.props.slideCount
    };
    let prevArrow;

    if (this.props.prevArrow) {
      prevArrow = React.cloneElement(this.props.prevArrow, {
        ...prevArrowProps,
        ...customProps
      });
    } else {
      prevArrow = (
        <button key="0" type="button" {...prevArrowProps}>
          {" "}
          <svg
            aria-hidden="true"
            className="svg-icon svg-icon-arrow-right "
            width="16"
            height="16"
            role="img"
            version="1.1"
            viewBox="0 0 18 15"
          >
            <path
              fillRule="nonzero"
              d="M5.056 6.5l4-4L7.5.944l-5 5L.944 7.5 7.5 14.056 9.056 12.5l-4-4h11.5v-2h-11.5z"
            />
          </svg>
        </button>
      );
    }

    return prevArrow;
  }
}

export class NextArrow extends React.PureComponent {
  clickHandler(options, e) {
    if (e) {
      e.preventDefault();
    }
    this.props.clickHandler(options, e);
  }
  render() {
    let nextClasses = {
      "slick-arrow horizScroll__control horizScroll__control--right": true,
      "slick-next horizScroll__control horizScroll__control--right": true
    };
    let nextHandler = this.clickHandler.bind(this, { message: "next" });

    if (!canGoNext(this.props)) {
      nextClasses["slick-disabled"] = true;
      nextHandler = null;
    }

    let nextArrowProps = {
      key: "1",
      "data-role": "none",
      className: classnames(nextClasses),
      style: { display: "block" },
      onClick: nextHandler
    };
    let customProps = {
      currentSlide: this.props.currentSlide,
      slideCount: this.props.slideCount
    };
    let nextArrow;

    if (this.props.nextArrow) {
      nextArrow = React.cloneElement(this.props.nextArrow, {
        ...nextArrowProps,
        ...customProps
      });
    } else {
      nextArrow = (
        <button
          className="horizScroll__control horizScroll__control--right"
          key="1"
          type="button"
          {...nextArrowProps}
        >
          {" "}
          <svg
            aria-hidden="true"
            className="svg-icon svg-icon-arrow-right "
            width="16"
            height="16"
            role="img"
            version="1.1"
            viewBox="0 0 18 15"
          >
            <path
              fillRule="nonzero"
              d="M12.944 6.5l-4-4L10.5.944l5 5L17.056 7.5 10.5 14.056 8.944 12.5l4-4h-11.5v-2h11.5z"
            />
          </svg>
        </button>
      );
    }

    return nextArrow;
  }
}
