"use strict";

import React from 'react';
import classNames from 'classnames';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var classes = classNames("O2logo", this.props.className, {
      academy: this.props.location && !this.props.location.toLowerCase().includes("the o2"),
    });

    return (
      <header className={!this.props.error ? 'artist-header' : null}>
        <nav>
          <div className="logos">
            <span className="priority" />
            <span className={classes} />
          </div>
        </nav>
        <h1>{this.props.artistTitle}</h1>
      </header>
    );
  }
}

Header.defaultProps = {
  location: '',
  artistTitle: '',
};
