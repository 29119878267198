"use strict";

import React from 'react';
import Slider from "./carousel/index.jsx";
import CarouselItem from "./carousel-item.jsx";
import { GraphQLClient } from "graphql-request";
import {getImprintid} from './app.jsx';

export default class SimpleSlider extends React.Component {
  self = this;

  async getCollection() {
    const endpoint = "https://graphql.amondo.com/";

    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization:
          "Bearer BearereyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTE3OSwicm9sZSI6InBhcnRuZXIiLCJlbWFpbCI6ImV0QGFtb25kby5jb20iLCJpYXQiOjE1NjQxNTI2MTAsImV4cCI6MTU2NjU3MTgxMH0.qGpEz41cWFgnxFRR-17ZFbzdkWZCmFB1gdsSPT_qEn8"
      }
    });

    const query = `query imprintWithCollection {
      imprint(id: ${getImprintid()}) {
        location,
            artist,
            collections {
          name,
              id,
              imprints {
            id,
                artist,
                location,
                startDate,
                imageUrl
          }
        }
      }
    }`;

    // const query =
    //   /* GraphQL */ `
    //   query imprintWithCollection {
    //     collection(id: ` +
    //   collectionId +
    //   `) {
    //       name
    //       id
    //       imprints {
    //         id
    //         artist
    //         location
    //         startDate
    //         imageUrl
    //       }
    //     }
    //
    //   }
    // `;

    const dataReturned = await graphQLClient.request(query);

    return dataReturned.imprint.collections.length ? dataReturned.imprint.collections[0].imprints : [];
  }

  constructor(props) {
    super(props);
    this.state = {
      data: "",
      loading: true
    };
  }
  componentDidUpdate() {
    if (this.state.data === "") {
      this.getCollection().then(items => {
        this.setState({
          loading: false,
          data: items
        });
      });
    }
  }

  componentDidMount() {}

  render() {
    let carouselItems = null;

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      swipeToSlide: true,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    if (this.state.loading === false && this.state.data.length) {
      carouselItems = this.state.data.map(eventItem => (
        <CarouselItem
          key={eventItem.id}
          imprintId={eventItem.id}
          eventTitle={eventItem.artist}
          eventDate={new Intl.DateTimeFormat("en-GB", {
            month: "long",
            day: "2-digit",
            year: "numeric"
          }).format(new Date(eventItem.startDate))}
          eventLocation={eventItem.location}
          eventImage={eventItem.imageUrl}
        />
      ));
    }


    return <Slider {...settings}>{carouselItems}</Slider>;
  }
}
