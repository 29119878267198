import React from 'react';
import ReactDOM from 'react-dom';

export default class CarouselItem extends React.Component {
  render() {
    let self = this;
    let url = [location.protocol, "//", location.host, location.pathname].join(
      ""
    );
    const urlParams = new URLSearchParams(window.location.search);

    let collectionUrl = url + "?imprintid=" + this.props.imprintId;

    const isgig = urlParams.get("isgig");
    if (isgig !== null) {
      collectionUrl = collectionUrl + "&isgig=true";
    }

    return (
      <div>
        <article itemScope="">
          <a href={collectionUrl} itemProp="url" className="listTile2__link">
            <div
              className="listTile2__images lazy"
              data-was-processed="true"
              style={{
                backgroundImage: `url(${this.props.eventImage})`
              }}
            />
            <div className="listTile2__content">
              <div className="listTile2__details">
                <h2 className="listTile2__title">{this.props.eventTitle}</h2>
                <div className="listTile2__subtitle">View more</div>
              </div>
            </div>
            <div className="listTile2__footer">
              <p className="status">
                <svg
                  aria-hidden="true"
                  className="svg-icon svg-icon-time-small status__icon"
                  width="15"
                  height="15"
                  role="img"
                  version="1.1"
                  viewBox="0 0 30 30"
                >
                  <g
                    transform="translate(-175.000000, -138.000000)"
                    fillRule="evenodd"
                  >
                    <g transform="translate(62.000000, 138.000000)">
                      <g transform="translate(113.000000, 0.000000)">
                        <path d="M14.6924,2.5725 C7.7994,2.5725 2.1924,8.1795 2.1924,15.0725 C2.1924,21.9655 7.7994,27.5725 14.6924,27.5725 C21.5854,27.5725 27.1924,21.9655 27.1924,15.0725 C27.1924,8.1795 21.5854,2.5725 14.6924,2.5725 M14.6924,29.5725 C6.6974,29.5725 0.1924,23.0675 0.1924,15.0725 C0.1924,7.0775 6.6974,0.5725 14.6924,0.5725 C22.6874,0.5725 29.1924,7.0775 29.1924,15.0725 C29.1924,23.0675 22.6874,29.5725 14.6924,29.5725" />
                        <path d="M19.2217,13.8244 L15.4017,17.6444 C15.2017,17.8454 14.9517,17.9344 14.6917,17.9344 C14.4317,17.9344 14.1817,17.8454 13.9817,17.6444 L8.7317,12.3944 C8.3417,12.0054 8.3417,11.3754 8.7317,10.9844 C9.1217,10.5954 9.7617,10.5954 10.1517,10.9844 L14.6917,15.5244 L17.8117,12.4044 C18.2017,12.0144 18.8317,12.0144 19.2217,12.4044 C19.6127,12.8044 19.6127,13.4344 19.2217,13.8244" />
                      </g>
                    </g>
                  </g>
                </svg>
                <span className="status__text">{this.props.eventDate}</span>
              </p>
              <p className="status">
                <svg
                  aria-hidden="true"
                  className="svg-icon svg-icon-location-small status__icon"
                  width="16"
                  height="16"
                  role="img"
                  version="1.1"
                  viewBox="0 0 16 16"
                >
                  <g fill="none" fillRule="evenodd">
                    <path d="M8.346 14.832l-.423.42-.423-.42c-.335-.334-.61-.602-1.22-1.192a92.18 92.18 0 0 1-1.644-1.618l-.064-.064-.112-.114c-.405-.41-.615-.628-.848-.889-.635-.712-1.072-1.362-1.356-2.093a5.91 5.91 0 0 1-.405-2.148c0-3.337 2.72-6.04 6.072-6.04 3.353 0 6.072 2.703 6.072 6.04 0 .734-.138 1.465-.404 2.147-.285.732-.722 1.382-1.358 2.095-.232.26-.442.478-.847.888l-.112.114-.063.064c-.449.455-.816.815-1.632 1.605-.622.603-.897.871-1.233 1.205zm.385-2.054a91.27 91.27 0 0 0 1.625-1.599l.064-.064.113-.114c.39-.396.59-.604.805-.844.548-.615.909-1.151 1.135-1.732a4.71 4.71 0 0 0 .322-1.711c0-2.673-2.18-4.84-4.872-4.84-2.691 0-4.872 2.167-4.872 4.84 0 .584.11 1.167.323 1.712.225.58.586 1.116 1.134 1.73a21.969 21.969 0 0 0 .918.959l.064.064c.44.447.803.802 1.612 1.586.37.358.613.594.821.798.208-.204.451-.44.808-.785z" />
                    <path d="M9.538 6.923a1.615 1.615 0 1 1-3.23 0 1.615 1.615 0 0 1 3.23 0" />
                  </g>
                </svg>
                <span className="status__text">{this.props.eventLocation}</span>
              </p>
            </div>
          </a>
        </article>
      </div>
    );
  }
}
