"use strict";

import React from 'react';

export default class Message extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
         return (
            <article className="error">
                <h2 className="highlightedList__heading">{this.props.heading}</h2>
                <p>{this.props.message}</p>
            </article>
        );
    }
}
